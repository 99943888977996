html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url(assets/fonts/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

.MuiTypography-body1 {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
